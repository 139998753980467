/* eslint-disable */

//***** SettingsGroup corresponds to database schema, this initializes an object to flesh out that just needs the JSON (Settings)
class SettingsGroup {
  constructor(obj = {}) {
  this.sgId = null;
  this.sgTypeId;
  this.sgName;
  this.userUid;      // This is a Uid
  this.sgSettings = null;  // This is JSON
  
  Object.assign(this, obj);
  }
}

module.exports = SettingsGroup;