/* eslint-disable */
import DatalogService from './DatalogService'
import Datalog2Service from './Datalog2Service'
import axios from 'axios';
import Moment from 'moment'
const momentrange = require('moment-range')
const moment = momentrange.extendMoment(Moment)
const url = 'api/appliance/';

class ApplianceService {

//*****************
//******* Get the appliance(s) associated with the specified Site
//*****************
static async dsGetAppliancesBySite (siteuid) {
  return new Promise(async (resolve, reject) => {
    // console.log('DataSourceService.dsGetBySite');
    // debugger
    try {
      const res = await axios.get(url+'applgetbysite', {
      params: {
        siteuid:siteuid,
      }
      });
      const data1 = res.data
      // console.log('data1 (dsGetBySite_DT)= ',data1)
      resolve(
        data1.data
      )  
    } catch(err) {
      reject(err);
    }
  });
  }

//*****************
//******* Get the appliance specified by its UID
//*****************
static async dsGetApplianceByUid (applianceuid) {
  return new Promise(async (resolve, reject) => {
    // console.log('DataSourceService.dsGetBySite');
    // debugger
    try {
      const res = await axios.get(url+'applgetbyuid', {
      params: {
        applianceuid:applianceuid,
      }
      });
      const data1 = res.data
      // console.log('data1 (dsGetBySite_DT)= ',data1)
      resolve(
        data1.data
      )  
    } catch(err) {
      reject(err);
    }
  });
}

//*****************
//******* Get Data from the API
//*****************

static async getData(startdate, enddate, ds_appliance, target_data, groupby_period) {
  return new Promise(async (resolve, reject) => {
    // debugger
    try {
      const res = await axios.get(url+'applgetdata', {
      params: {
        starttimestamp:startdate,
        endtimestamp: enddate,
        ds_appliance:ds_appliance,
        target_data: target_data,
        groupby_period:groupby_period
      }
      });
      const data1 = res.data
      resolve(
        data1.data
      )  
    } catch(err) {
      reject(err);
    }
  });

}

}  // End of ApplianceService Class



// **************************************************
// *** Tank1 type appliance is a tank with discrete fill events and a single metered outlet
// **************************************************
class Tank1SystemAppliance {
  constructor(applianceuid, appliancename, description, liquidtype, storageunits, usageunits, capacity, filllevelnominal, usagedatasource, filldatasource, meterdatasource)  {
    this.applianceUid = applianceuid    // unique identifier of the specific appliance
    this.schemaId = 1000           // schemaId isd a key to link specific appliance schema & code
    this.applianceName = appliancename                    // formal name for identification
    this.description = description
    this.liquidType = liquidtype        // "water", "propane", "gasoline", "diesel"
    this.storageUnits = storageunits    // "gallon" or "liter"
    this.usageUnits = usageunits   // "gallon" "cft" or "liter"
    this.capacity = capacity            // in this.storageUnits
    this.fillLevelNominal = filllevelnominal  // in this.storageUnits
    this.fillDatasource = filldatasource      // datasource for fill events
    this.usageDatasource = usagedatasource    // datasource for usage events
    this.meterDatasource = meterdatasource    // datasource for manual usage meter readings
  }

  // ***** get fill events that AFFECT tank levels within the specified data range
  async getFillEvents(startdate, enddate) {
    // Get all fill entries from the previous one before startdate, to the last one before enddate
    // Use 6 months for the reachback (this is the longest period between fills)
    const start = moment(startdate).subtract(6,'month').format('YYYY-MM-DD')
    let rows = await Datalog2Service.getDL2RecordByUid(start,enddate,this.fillDatasource)
    let fillstoinclude = []
    rows = rows.reverse()
    // back up until there is only the previous event ahead of startdate
    for (let i=0; i < rows.length-1; i++) {
      if (moment(rows[i].timeStamp).format('YYYY-MM-DD') >= startdate) {
        fillstoinclude.push(rows[i])
      } else {
        fillstoinclude.push(rows[i])
        break;
      }
    }
    fillstoinclude = fillstoinclude.reverse()
    return fillstoinclude
  }

  // ***** get the daily usage for a specified period
  async getHourlyUsage(leveldate) {
    try {
    const hrusage = await DatalogService.getHourlySums(leveldate, this.usageDatasource)
    // debugger
    return hrusage
  } catch (err) {
    console.log('Tank1SystemAppliance - getHourlyUsage: err= ', err.message)
  }
}

  // ***** get the hourly tank level for a specific date
  // ***** specify the estimated startlevel at midnight
  // ***** string together multiple calls to get hours over a series of days
  async getHourlyTankLevels(leveldate) {
    try {
      const tankleveldate = moment(leveldate).subtract(1, 'days').format('YYYY-MM-DD')
      const startlevel = await this.getTankLevel(tankleveldate)
      const hrtotals = await this.getHourlyUsage(leveldate)
      let results = []
      // Create the data
      let tanklevel = startlevel
      for (let i = 0; i < 24; i++) {
        tanklevel -= ConvertUnits(hrtotals[i].groupTotals, this.usageUnits, this.storageUnits)
          results.push({
          hour:i,
          level:tanklevel
        }) }
        // debugger
        return results
    } catch (err) {
      console.log('Tank1SystemAppliance - getHourlyTankLevels: err= ', err.message)
    }
  }

  // ***** get the daily usage for a specified period
  async getDailyUsage(startdate, enddate) {
    try {
    const days = await DatalogService.getDailySums0(startdate, enddate, this.usageDatasource)
    return days
    } catch (err) {
      console.log('Tank1SystemAppliance - getDailyUsage: err= ', err.message)
    }
  }

  // ***** get the daily tank level over a specified period
  async getDailyTankLevels(startdate, enddate) {
    try {
    const fillevents = await this.getFillEvents(startdate, enddate)
    const days = await this.getDailyUsage(fillevents[0].timeStamp, enddate)
    let results = [], tanklevel=0, j=0, settings=''
    // Create the data
    for (let i = 0; i < days.length; i++) {
      if ((j <= fillevents.length - 1) && moment(days[i].saveDateIso).format('YYYY-MM-DD') == (moment(fillevents[j].timeStamp).format('YYYY-MM-DD'))) { 
        // This is a fill day, reset the tank level
        settings = JSON.parse(fillevents[j].jsonData)
        tanklevel = settings.fillLevel 
        j++
      } else { 
        // Not a fill day, so decrement by usage amount for this day
          tanklevel -= ConvertUnits(days[i].groupTotals,this.usageUnits, this.storageUnits)  
        }
      const saveMonthDay = days[i].saveMonthDay
      const saveDateIso = days[i].saveDateIso
      const rowCounts = days[i].groupRowCounts
      if(moment(saveDateIso).format('YYYY-MM-DD') >= moment(startdate).format('YYYY-MM-DD')) {
        results.push({
          groupTotals:tanklevel,
          saveMonthDay:saveMonthDay,
          saveTime: saveDateIso,
          groupRowCounts:rowCounts
        })
      }
      // debugger
      }
      return results
    } catch (err) {
      console.log('Tank1SystemAppliance - getDailyTankLevels: err= ', err.message)
    }
  }

  // ***** get the tank level at a specified moment
  async getTankLevel(leveltime) {
    const fillevents = await this.getFillEvents(leveltime, leveltime)
    let periodusage = await DatalogService.getAggregates(fillevents[0].timeStamp, moment(leveltime).format('YYYY-MM-DD'), this.usageDatasource)
    periodusage = ConvertUnits(periodusage[0].periodSum, this.usageUnits, this.storageUnits)
    return this.fillLevelNominal-periodusage
  }

// **************************************************
} // end class Tank1SystemAppliance
// **************************************************


// ***
// *** Fill event that completely fills the tank
// ***
class TankFillEvent {
  constructor(applianceuid, filldate, filledamount, unitprice, filltotalamount) {
    this.schemaId = 200
    this.applianceUid = applianceuid
    this.fillDate = filldate
    this.filledAmount = filledamount
    this.unitPrice = unitprice
    this.fillTotalAmount = filltotalamount
  }
   
} // end class FillEvent

// **************************************************
// *** Well1 type appliance is essentially a tank with continuous variable, rate fill 
// *** and 2 metered outputs. averageRecoveryRate is a simplified model that can be
// *** used until a recovery rate curve is established.
// **************************************************
class Well1SystemAppliance {
  constructor(applianceuid, appliancename, description, filled_wellwater_level, pumping_level, water_pipe_diameter, well_diameter, average_recovery_rate, recovery_rate_curve_uid, usage1_datasource, usage2_datasource)  {
    this.applianceUid = applianceuid    // unique identifier of the specific appliance
    this.schemaId = 1001           // schemaId isd a key to link specific appliance schema & code
    this.applianceName = appliancename  // formal name for identification
    this.description = description
    this.storageUnits = "gal"    // "gal" or "liter"
    this.usageUnits = "gal"   // "gal" "cft" or "liter"
   this.filledWellWaterLevel = filled_wellwater_level // 330 (ft) highest normal static level above pumping level
    this.pumpingLevel = pumping_level  // 96 (ft) lowest functional level to pump down to
    this.waterPipeDiameter = water_pipe_diameter // 1 (in) water pipe running from pump to top of well
    this.wellDiameter = well_diameter  // 6 (in) effective diameter of well
    this.averageRecoveryRate = average_recovery_rate // rate per minute of water volume recovery, on average (without respect to water level)
    this.recoveryRateCurveUid = recovery_rate_curve_uid // Uid of specific rate curve simulator, which has its own parameters including water level
    this.usage1DataSource = usage1_datasource    // datasource for usage events
    this.usage2DataSource = usage2_datasource    // second datasource for usage events
  }

  // ***** get the hourly usage for a specified period
  async getHourlyUsage(leveldate) {
    const hours = await DatalogService.getHourlySums(leveldate, this.usageDatasource)
    debugger
    return hours
  }

  // ***** get the hourly well level for a specific date
  // ***** specify the estimated startlevel at midnight
  // ***** string together multiple calls to get hours over a series of days
  async getHourlyWellLevels(leveldate, startlevel) {
    try {
    const hrtotals = await this.getHourlyUsage(leveldate)
    let results = []
    // Create the data
      let welllevel = startlevel
    for (let i = 0; i < 24; i++) {
      welllevel -= hrtotals[i].map(hr => {return hr.groupTotals})
      welllevel += this.averageRecoveryRate * 60

      results.push({
        hour:i,
        level:welllevel
      })
      }
      debugger
      return results
    } catch (err) {
      console.log('Tank1SystemAppliance - getDailyTankLevels: err= ', err.message)
    }
  }


} // end class Well1SystemAppliance


// Helpers*******************************************************************
function ConvertUnits (valuein , unitsin , unitsout ) {
  let valueout
  // Pounds per square inch of air pressure in a well airtube
  if (unitsin == 'psi') {
    if (unitsout == 'gal' || unitsout == 'gallon') {
      valueout = valuein * 2.31 * 1.05 // based on 6" well diameter
        return valueout
    } }
  // Cubic ft of natural gas / propane flowing from a tank
  if (unitsin =='cft') {
    if (unitsout == 'gal' || unitsout == 'gallon') {
      valueout = valuein * .012 // 82.62 cft in a gallon of natural gas
      return valueout
    } }
return valuein // return what went in unless there was a successful conversion
}

export default ApplianceService;
// export default Tank1SystemAppliance;