<!-- eslint-disable -->

<template>
  <v-container fluid>
    <v-toolbar-title class="green lighten-5">{{wellchart1title}}</v-toolbar-title>
  <v-row no-gutters>
    <!-- ***** Update Chart ***** -->
    <v-btn
      color="green"
      dark
      small 
      class="mt-2 mr-4"
      @click='UpdateChart'
      >
      Update Chart
    </v-btn>
    <!-- ***** Save Settings ***** -->
    <v-dialog v-model="dialog2" max-width="320px" persistent>
      <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="light-blue"
        dark
        small 
        class="mt-2 mr-4"
        v-bind="attrs"
        v-on="on"
        @click="savesettingsformkey += 1"
        >Save Settings  </v-btn>
      </template>
      <save-settings-form
        title="Save Report Settings"
        v-on:cancelentry="onCancel2"
        v-on:saveentry="onSaveSettings"
        :key="savesettingsformkey"
      >
      </save-settings-form>
    </v-dialog>
    <!-- ***** Show Filters ***** -->
    <v-btn
      color="green lighten-5"
      small
      class="mt-2 mr-4 black--text"
      @click='toggleFilters'
      >
      {{ filterbtntext }}
    </v-btn>
    <!-- ***** Show Summary ***** -->
    <v-btn
      color="green lighten-5"
      small
      class="mt-2 mr-4 black--text"
      @click='toggleSummary'
      >
      {{ summarybtntext }}
    </v-btn>
    <!-- ***** Show Config ***** -->
    <v-btn
      color="green lighten-5"
      small
      class="mt-2 mr-4 black--text"
      @click='toggleConfig'
      >
      {{ configbtntext }}
    </v-btn>
    <!-- ***** Delete Saved Report ***** -->
    <v-btn v-if="id > 0"
      color="red lighten-1"
      small
      dark
      class="mt-2 mr-4"
      @click='onDeleteSavedReport'
      >
      Delete This Report
    </v-btn>
    <!-- ***** Debug Instance ***** -->
    <v-btn
      color="red"
      text 
      @click='debuginstance++'
      >
      Debug Instance
    </v-btn>
  </v-row>
    <!-- *********************************** -->
    <!-- ***** Filter / Settings Array ***** -->
    <!-- *********************************** -->
    <template>
      <div :key=showf v-if="showfilters">
      <v-row no-gutters>
    <!-- ***** Select Organization ***** -->
        <v-col cols="2">
          <v-select
            v-on:change="onChangeOrganization"
            v-model="sel_org"
            :items="cb_orgs"
            item-text="name"
            item-value="uid"
            label="Organization"
            outlined
            dense
            class="mt-4"
          ></v-select>
        </v-col>
    <!-- ***** Select Site ***** -->
        <v-col cols="2">
          <v-select
            v-on:change="onChangeSite"
            v-model="sel_site"
            :items="cb_sites"
            item-text="name"
            item-value="uid"
            label="Site"
            outlined
            dense
            class="mt-4"
          ></v-select>
        </v-col>
    <!-- ***** Select Well ***** -->
        <v-col cols="3">
          <v-select
            v-on:change="onChangeWell"
            v-model="sel_well"
            :items="cb_wells"
            item-text="applName"
            item-value="uid"
            label="Well"
            outlined
            dense
            class="mt-4"
          ></v-select>
        </v-col>

      </v-row>
      <v-row no-gutters>
        <!-- Switch between Preset and Custom Date Range modes -->
        <v-col cols="3">
          <v-btn
            class="mr-3"
            elevation="1"
            fab
            small
            color="success"
            @click="sw_dmode = ! sw_dmode"
          >
            <v-icon v-if="sw_dmode">
              mdi-calendar-multiple
            </v-icon>
            <v-icon v-else>
              mdi-dns
            </v-icon>
          </v-btn> -->
          {{sw_dmodelabel}}
        </v-col>
        <v-col 
          v-if="!sw_dmode"
          cols="2">
          <v-select
            v-model="sel_drange"
            :items="cb_drange"
            label="Date Range"
            item-text="name"
            item-value="id"
            outlined
            color="green"
            red--text
            dense
            return-object
          ></v-select>
        </v-col>

      <!-- Start Date -->
        <v-col cols="2"
          v-if="sw_dmode"
        >
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="datastartdate"
            label="Start Date"
            readonly
            outlined
            dense
            color="green"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="datastartdate"
          no-title
          scrollable
          @input="menu = false"
        >
        </v-date-picker>
      </v-menu>
      </v-col>

      <!-- End Date -->
      <v-col cols="2"
        v-if="sw_dmode"         
        >
      <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dataenddate"
            label="End Date"
            readonly
            outlined
            dense
            color="green"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="dataenddate"
          no-title
          scrollable
          @input="menu = false"
        >
        </v-date-picker>
      </v-menu>
      </v-col>

      <!-- Group By -->
        <v-col cols="2">
          <v-select
            v-model="sel_groupby"
            :items="cb_groupby"
            label="Group By"
            item-text="name"
            item-value="id"
            outlined
            color="green"
            dense
            return-object
          ></v-select>
        </v-col>
      </v-row>
      </div>
      <div v-else>
        <v-divider> </v-divider>
      </div>
    </template>
    <template
      v-if="dontshow_chart"
    >     
      <p class="text-center text-h4 grey--text">(No Data to Chart)
      </p>
    </template>
    
    <template
      v-if="!dontshow_chart"
    >     
    <v-row>
      <v-col cols="12">
<!-- *********** DATA CHART 1 HERE ************ -->
        <vertical-combo-chart5
          :ChartLabels="Chart1Labels"
          :barChart1Label = "barChart1Label"
          :barChart1AxisId = "barChart1AxisId"
          :barChart1Hidden="barChart1Hidden"
          :barChart1Datax="barChart1Datax"
          :barChart1Datay="barChart1Datay"
          :lineChart1Label="lineChart1Label"
          :lineChart1AxisId = "lineChart1AxisId"
          :lineChart1Hidden="lineChart1Hidden"
          :lineChart1Datax="lineChart1Datax"
          :lineChart1Datay="lineChart1Datay"
          :lineChart2Label="lineChart2Label"
          :lineChart2AxisId = "lineChart2AxisId"
          :lineChart2Hidden="lineChart2Hidden"
          :lineChart2Datax="lineChart2Datax"
          :lineChart2Datay="lineChart2Datay"
          :yAxisLeft="yAxis1Left"
          :yAxisRight="yAxis1Right"
          :yAxisLeftLabel="yAxis1LeftLabel"
          :yAxisRightLabel="yAxis1RightLabel"
          :key="keychart1"
        />
      </v-col>
    </v-row>
    </template>

    <v-row>
        <v-divider v-if="show_datatable_data"> </v-divider>
    </v-row>
<!-- **************************************** -->
<!-- *********** DATA TABLE HERE ************ -->
<!-- **************************************** -->
    <v-row>
      <v-col cols="12">
      <v-data-table
        v-if="show_datatable_data"
        dense
        :headers="data_headers"
        :hide-default-footer="true"
        :items="datatable_data"
        :sort-desc="true"
      >
      </v-data-table>
      </v-col>
      </v-row>




    <v-row>
        <v-divider v-if="show_datatable_config"> </v-divider>
    </v-row>
<!-- **************************************** -->
<!-- ********** CONFIG DATA TABLE HERE ****** -->
<!-- **************************************** -->
    <template
      v-if="show_datatable_config"
    >     
    <v-row>
      <v-col cols="12">
      <v-data-table
        dense
        v-if="show_datatable_config"
        :headers="config_headers"
        :hide-default-footer="true"
        :items="datatable_config"
        :items-per-page="20"
      >
      </v-data-table>
      </v-col>
    </v-row>
    <v-row>
        <v-divider v-if="show_datatable_config"> </v-divider>
    </v-row>
    </template>
  </v-container>
</template>

<script>

/* eslint-disable */
//************************
// JAVASCRIPT
//************************
import ApplianceService from '../../services/ApplianceService'
import CrmService from '../../services/CrmService'
import helperMixin from '@/mixins/helperMixin'
import Moment from 'moment'
// import DatalogService from '../../services/DatalogService'
// import ConfigService from '../../services/ConfigService'
import VerticalComboChart5 from '../../components/charts/VerticalComboChart5'
import SettingsGroupService from '../../services/SettingsGroupService'
import SaveSettingsForm from '../../components/forms/SaveSettingsForm.vue'
import { EventBus } from '../../event-bus.js'
var SG = require('../../../server/models/m_settingsgroup')
var GS = require( '../../models/m_groupsettings')
const momentrange = require('moment-range')
const moment = momentrange.extendMoment(Moment)

export default {
  name: 'wellchart1',
  components: {VerticalComboChart5,SaveSettingsForm},
  mixins: [helperMixin],
  props: { 
    id: {
    type: String,
    default: '0'
  }},

//************************
// DATA
//************************
  data () {  return {
    initializing: true,
    keychart1: 0,
    keychart2: 0,
    dialog2: false,
    showf: 0,
    showc: 0,
    savesettingsformkey: 0,
    debuginstance: 0,
    menu1: false,
    menu2: false,
    wellchart1title: 'Well Chart 1 (Base)',
    weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    // Chart Series
    hours24: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],

    // Data Table (Summary)
    show_datatable_data: true,
    init_data_headers: [
      { text: 'Start Date',sortable: true, value: 'startDate', class: "green lighten-5 black--text subtitle-2", align: "start"},
      { text: 'End Date',sortable: true, value: 'endDate', class: "green lighten-5 black--text subtitle-2", align: "start"},
      { text: 'Total Outflow',sortable: true, value: 'totalOutflow', class: "green lighten-5 black--text subtitle-2", align: "start"},
      { text: 'Total Inflow',sortable: true, value: 'totalInflow', class: "green lighten-5 black--text subtitle-2", align: "start"},
      { text: 'Start Volume',sortable: true, value: 'startVolume', class: "green lighten-5 black--text subtitle-2", align: "start"},
      { text: 'End Volume',sortable: true, value: 'endVolume', class: "green lighten-5 black--text subtitle-2", align: "start"},
      { text: 'Min Volume',sortable: true, value: 'minVolume', class: "green lighten-5 black--text subtitle-2", align: "start"},
      { text: 'Max Volume',sortable: true, value: 'maxVolume', class: "green lighten-5 black--text subtitle-2", align: "start"},
        ],
      data_headers: [],
      datatable_data: [],
    // Config Table   
    show_datatable_config: true,
    init_config_headers: [
        { text: 'Configuration Parameter:',sortable: true, value: 'parameter', class: "green lighten-5  black--text subtitle-2", align: "start"},
        { text: 'Value',sortable: true, value: 'value', class: "green lighten-5 black--text subtitle-2", align: "start"},
        { text: 'Description',sortable: true, value: 'description', class: "green lighten-5 black--text subtitle-2", align: "start"},
        ],
    config_headers: [],
    datatable_config: [],
    // Chart params
    Chart1Labels: [],  // Not sure what this does
    barChart1Datax: [],
    barChart1Datay: [],
    lineChart1Datax: [],
    lineChart1Datay: [],
    lineChart2Datax: [],
    lineChart2Datay: [],
    barChart1Label: 'Water Outflow',
    lineChart1Label: 'Well Water Volume',
    lineChart2Label: 'Well Recovery',
    barChart1Hidden: false,
    lineChart1Hidden: false,
    lineChart2Hidden: false,
    barChart1AxisId: "idleft", // "idleft" or "idright"
    lineChart1AxisId: "idleft", // "idleft" or "idright"
    lineChart2AxisId: "idleft", // "idleft" or "idright"
    yAxis1Left: true,  // show left axis?
    yAxis1Right: false, // show right axis?
    yAxis1LeftLabel: 'Water (Gallons)',
    yAxis1RightLabel: '',
    
    //********** Filters / Settings Data */
    sgTypeId: 105,              // Settings Group type id for WellChart1
    sgTypeName: "Well Chart 1", // Settings Group type name for WellChart1
    reportname: 'Well Chart 1',
    showfilters: 'true',
    dontshow_chart: true,
    datastartdate: '',
    dataenddate: '',
    sel_org: {},
    cb_orgs: [],
    sel_site: {},
    cb_sites: [],
    // Well Appliance
    sel_well: {},
    cb_wells: [],
    sel_dsbar1: null,
    sel_dsline1: null,
    sel_dsline2: null,
    // Time/Date
    sw_dmode: false,  // false = Preset , true = Custom
    sw_dmodelabel: 'Date Range Mode: Preset',
    sel_drange: {name: 'Last Week', id: '0'},
    cb_drange: [
      {name: 'This Week', id: '0', disabled: false},
      {name: 'Last Week', id: '1', disabled: false},
      {name: 'Today', id: '2', disabled: false},
      {name: 'Yesterday', id: '3', disabled: false},
      {name: 'This Month', id: '4', disabled: true},
      {name: 'Last Month', id: '5', disabled: true},
      {name: 'This Quarter', id: '6', disabled: true},
      {name: 'Last Quarter', id: '7', disabled: true},
      {name: 'This Year', id: '8', disabled: true},
      {name: 'Last Year', id: '9', disabled: true},
    ],
    sel_groupby: {name: 'Day', id: '1', disabled: false},
    cb_groupby: [
      {name: 'Hour', id: '0', disabled: false},
      {name: 'Day', id: '1', disabled: false},
      // {name: 'Week', id: '2', disabled: true},
      // {name: 'Month', id: '3', disabled: true},
      // {name: 'Year', id: '4', disabled: true}
      ],
  }
},
//************************
// WATCH
//************************
watch: {
  debuginstance (val) {
debugger
  },
  datastartdate (val) {
    if (val > this.dataenddate) this.dataenddate = val
  },
  dataenddate (val) {
    if (val < this.datastartdate) this.datastartdate = val
  },
  sw_dmode (val) {
    this.sw_dmodelabel = !val ? 'Date Range Mode: Preset' : 'Date Range Mode: Custom'
    this.setGroupBy()
  },
  sel_drange (val) {
    this.setGroupBy()
  },
  sel_well_index (val) {
    this.sel_well = this.cb_wells[val]
  },

},
//************************
// COMPUTED
//************************
computed: {
  filterbtntext () {
    const fbt = this.showfilters ? 'Hide Filters' : 'Show Filters'
    return fbt
  },
  
  summarybtntext () {
    const sbt = this.show_datatable_data ? 'Hide Summary' : 'Show Summary'
    return sbt
  },

  configbtntext () {
    const cbt = this.show_datatable_config ? 'Hide Config' : 'Show Config'
    return cbt
  }
},
//************************
// METHODS
//************************
methods: {

//***** Cancel out the save settings dialog
  onCancel2 () {this.dialog2 = false},

//************************
// Initialization
//************************
/*
For 3/14/22:
 - get list of the well(s) that are defined for the selected site ID
 - probably a pull-down at some point in event there is more than one well
 - Schema ID provides match to appl_well1_1001.js appliance
 - instantiate the appliance and call getData() with instantiated appliance (through ApplianceTypeRouter() )
*/

  initAll() {
    this.initializing = true
    this.initOrganizations()
  },

  onChangeOrganization() {
    this.clearChartData()
    // this.clearDataSources()
  },

  async initOrganizations() {
    try {
      let cborgs = [];
      const loggedin = this.$store.state.loggedin
      const current_customer = this.$store.state.current_customer
      const orgs = await CrmService.customerSelectAll(loggedin, current_customer);
      cborgs = orgs.map(o => ({ name: o.organizationName, uid: o.customerUid }));
      this.cb_orgs = cborgs
      this.sel_org = this.cb_orgs[0].uid;
      // console.log('ApplianceChart2 > initOrganizations: cb_orgs = ', this.cb_orgs); 
      this.initSites(this.sel_org);
    } 
    catch (err) {
      console.log(err.message);
    }
  },

  onChangeSite() {
    this.clearChartData()
  },

  async initSites(organization) {
    try {
      let cbsites = [];
      const sites = await CrmService.siteSelectByCustomerUid(organization);
      if (sites.length == 0) {throw "No site found for current organization!"}
      cbsites = sites.map(s => ({ name: s.siteName, uid: s.siteUid }));
      this.cb_sites = cbsites
      this.selectSite(cbsites[0].uid);
      // console.log('ApplianceChart2 > populateSites: cb_orgs = ', this.cb_sites); 
      this.initWells()
    } 
    catch (err) {
      console.log(err.message);
    }
  },

  async onChangeWell() {
    this.clearChartData()
  },

// ***** initialize Appliances Select control (fully instantiated with appliance objects)
async initWells() {
  try {
    this.cb_wells = []
  // <TBD> this method will get appliances for the current Org & Site from the appliances db table
  console.log(ApplianceService)
    const appl = await ApplianceService.dsGetAppliancesBySite(this.sel_site)
    let cb_wells = []
    let item, index = 0
    for (item of appl) {
      if (item.schemaId == 1001) { // It's a well so add it
        cb_wells.push(item)
        this.sel_well = cb_wells[0]  // Later should change 0 to index
      index++; }
    }
    this.cb_wells = cb_wells
  // *****
  // ***** Load Settings if this is instantiated as a saved report
  // *****
    const cid = this.id
    // If id = 0, this is a base report, otherwise it is a saved report
    if (cid > 0) {
      // Selections will be made from the saved settings
      this.loadSettings(cid)
    } // else just leave them blank
    // Init the configuration data table
    this.initConfig() 
  } catch (err) {
    console.log('WellChart1 - initWells: err= ', err.message)
  }
},

// Put the final period calculations into the data table
initSummaryTable(lastperiod) {
  let datatable = []
  datatable.push( { 
    startDate: this.datastartdate,
    endDate: this.dataenddate, 
    totalOutflow: lastperiod.duration_outflow,
    totalInflow: lastperiod.duration_inflow,
    startVolume: lastperiod.duration_startvolume,
    endVolume: lastperiod.period_endvolume,
    minVolume: lastperiod.duration_minvolume,
    maxVolume: lastperiod.duration_maxvolume} )

  this.data_headers = this.init_data_headers
  this.datatable_data = datatable
},

initConfig() {
  const config = JSON.parse(this.sel_well.jsonData)
  let datatable = []
  datatable.push( {parameter: "applianceName", value: config.applianceName, description: ""})
  datatable.push( {parameter: "schemaId", value: config.schemaId, description: "Single recovery, multiple outflows"})
  datatable.push( {parameter: "applianceUid", value: config.applianceUid, description: "Unique ID of this well"})
  datatable.push( {parameter: "wellDepth", value: config.wellDepth, description: "Single recovery, multiple outflows"})
  datatable.push( {parameter: "wellDiameter", value: config.wellDiameter, description: "Diameter in inches of the well"})
  datatable.push( {parameter: "waterPipeDiameter", value: config.waterPipeDiameter, description: "Diameter in inches of water pipe"})
  datatable.push( {parameter: "staticLevel", value: config.staticLevel, description: "Level of water in well when fully recovered"})
  datatable.push( {parameter: "drawdownHeight", value: config.drawdownHeight, description: "Level of water in well when fully recovered"})
  datatable.push( {parameter: "drawdownVolume", value: config.drawdownVolume, description: "Safely available water volume when fully recovered"})
  datatable.push( {parameter: "aveRecoveryRate", value: config.aveRecoveryRate, description: "Average Recovery Rate per minute - depth invariant"})
  datatable.push( {parameter: "levelUnits", value: config.levelUnits, description: "Units of measurre for linear measurements"})
  datatable.push( {parameter: "storageUnits", value: config.storageUnits, description: "Units of volume for water in the well"})
  datatable.push( {parameter: "usageUnits", value: config.usageUnits, description: "Units of volume for water pumped from well"})
  
  this.config_headers = this.init_config_headers
  this.datatable_config = datatable
}, 

setGroupBy() {
  // Disable/enable items based on date range selector
  if (!this.sw_dmode) { // Presets data range mode
  // set selection based on this.sel_drange.id
    switch (Number.parseInt(this.sel_drange.id)) {
      case 0: { // This Week
        this.cb_groupby[0].disabled = true 
        this.cb_groupby[1].disabled = false 
        // this.cb_groupby[2].disabled = true 
        // this.cb_groupby[3].disabled = true 
        // this.cb_groupby[4].disabled = true 
        this.sel_groupby =  this.cb_groupby[1]
        break;
      }
      case 1: { // Last Week
        this.cb_groupby[0].disabled = true 
        this.cb_groupby[1].disabled = false 
        // this.cb_groupby[2].disabled = true 
        // this.cb_groupby[3].disabled = true 
        // this.cb_groupby[4].disabled = true 
        this.sel_groupby =  this.cb_groupby[1]
        break;
      }
      case 2: { // Today
        this.cb_groupby[0].disabled = false 
        this.cb_groupby[1].disabled = true 
        // this.cb_groupby[2].disabled = true 
        // this.cb_groupby[3].disabled = true 
        // this.cb_groupby[4].disabled = true 
        this.sel_groupby =  this.cb_groupby[0]
        break;
      }
      case 3: { // Yesterday
        this.cb_groupby[0].disabled = false 
        this.cb_groupby[1].disabled = true 
        // this.cb_groupby[2].disabled = true 
        // this.cb_groupby[3].disabled = true 
        // this.cb_groupby[4].disabled = true 
        this.sel_groupby =  this.cb_groupby[0]
        break;
      }
    }
// debugger
    } else { // Custom data range mode
      if (this.datastartdate == this.dataenddate) { // one day, so hours only
          this.cb_groupby[0].disabled = false 
          this.cb_groupby[1].disabled = false 
          // this.cb_groupby[2].disabled = true 
          // this.cb_groupby[3].disabled = true 
          // this.cb_groupby[4].disabled = true 
          this.sel_groupby =  this.cb_groupby[0]
      } else  { // days, weeks
          this.cb_groupby[0].disabled = false 
          this.cb_groupby[1].disabled = false 
          // this.cb_groupby[2].disabled = true 
          // this.cb_groupby[3].disabled = true 
          // this.cb_groupby[4].disabled = true 
          this.sel_groupby =  this.cb_groupby[1]
      }
    }
},

  toggleFilters() {
    this.showfilters = !this.showfilters
    this.showf += 1
  },

  toggleSummary() {
    this.show_datatable_data = !this.show_datatable_data
    this.showf += 1
  },

  toggleConfig() {
    this.show_datatable_config = !this.show_datatable_config
    this.showc += 1
  },

  clearChartData () {
    this.barChart1Datay = []
    this.barChart1Datax = []
    this.lineChart1Datax = []
    this.lineChart1Datay = []
    this.lineChart2Datax = []
    this.lineChart2Datay = []
    this.dontshow_chart = true
    this.dontshow_config = true
  },

  async onDeleteSavedReport() {
    try {
    SettingsGroupService.deleteSettingsGroup(this.id)
      // Update the Saved Reports Nav Drawer
      EventBus.$emit('updateCVDrawer', true)
      alert ("This Saved Report has been deleted!")
      this.$router.push({path: '/basereports/wellchart1/0'})

    } catch (err) {
      console.log('WellChart1> deleteSettingsGroup ERROR = ', err.message);
    }
  },

//************ Load the saved settings designated by the PK sgid
  async loadSettings(sgid) {
    this.dialog2 = false
    try {
      this.loadingsettings == true
      const settingsgroup = await SettingsGroupService.getSettingsGroupById(sgid)
      const settings = JSON.parse(settingsgroup.sgSettings)
      // console.log(settings)
      if (settings.sgTypeId != this.sgTypeId) throw 'Loaded Settings Group TypeID Mismatch - this component requires sgTypeID = ' + this.sgTypeId
      // Set the settings for this component
      this.showfilters = settings.showFilters
      this.show_datatable_data = settings.showSummary
      this.show_datatable_config = settings.showConfig
      this.reportname = "(SAVED): \"" + settings.reportName + "\""
      this.sw_dmode = settings.dateRangeMode
      this.sel_drange = settings.presetDateRange
      // debugger
      this.sel_groupby = settings.groupBy
      this.setGroupBy()
      this.datastartdate = settings.customDateRange[0]
      this.dataenddate = settings.customDateRange[1]
      this.selectOrg(settings.organization)
      this.selectSite(settings.site)
      // debugger
      this.UpdateChart()
      if (this.showfilters != settings.showFilters) this.toggleFilters()
    } catch (err) {
      console.log('WellChart1 > loadSettings ERROR = ', err.message);
    } finally {
  } 
  },

//************ Handler for saving current settings (Called from SaveSettingsForm component)
  async onSaveSettings (newsgName) {
    try {
      this.dialog2 = false
      // meta
      const sgtypeid = this.sgTypeId
      const sgtypename = this.sgTypeName
      const reportname = newsgName
      const showfilters = this.showfilters
      const showsummary = this.showsummary
      const showconfig = this.showconfig
      // owner
      const userUid = this.$store.state.loggedin.userUid
      const sel_org = this.sel_org
      const sel_site = this.sel_site
      // time/date
      const sw_dmode = this.sw_dmode
      const datastartdate = this.datastartdate
      const dataenddate = this.dataenddate
      const sel_drange = this.sel_drange
      const sel_groupby = this.sel_groupby
      //
      var settings = new GS.SGWellChart1(reportname, showfilters, showsummary, showconfig, sel_org, sel_site, sw_dmode, [datastartdate,dataenddate], sel_drange, sel_groupby) 
      //
      const newsg = await SettingsGroupService.createSettingsGroup(sgtypeid, sgtypename, userUid, settings)
      console.log("ComboTable > onSaveSettings: newsg = ", newsg)
      // Create Saved Reports Link
      EventBus.$emit('updateCVDrawer', true)

    } catch (err) {
      console.log('WellChart1 > onSaveSettings ERROR = ', err.message);
    }
    },


  // Validate that org we want to (programmatically) select is in the select list
  //  Used when recalling saved settings (maybe the list of orgs is different now)
  selectOrg(organization) {
  try {  
    const orgs = this.cb_orgs 
    let orgselected = false
    for (let org of orgs) {
      if (organization == org.uid) {
        this.sel_org = org.uid
        orgselected = true
      }
    }
    if (!orgselected) { throw "Organization to select is not available !"}
    }
    catch (err) {
      console.log('ApplianceChart2 > selectOrg ERROR = ', err.message);
    }
  },

// Organizations can have more than one site: Set the selected site based on parameter (Uid)
  selectSite(siteuid) {
    try {
      // debugger
    // Search through the list of available sites to select from
      const sites = this.cb_sites
      let siteselected = false
      for (let site of sites) {
        if (siteuid == site.uid)
        { this.sel_site = site.uid 
          siteselected = true }
      }
      if (!siteselected) { throw "Site to select is not available !"}
    } catch (err) {
      console.log('WellChart1 > selectSite ERROR = ', err.message);
    }
  },

// Sites can have more than one well: Set the selected well based on parameter (Uid)
  selectWell(welluid) {
    try {
      // debugger
    // Search through the list of available wells to select from
      const wells = this.cb_wells
      let wellselected = false
      for (let well of wells) {
        if (welluid == well.uid)
        { this.sel_well = well.uid 
          wellselected = true }
      }
      if (!wellselected) { throw "Well to select is not available !"}
    } catch (err) {
      console.log('WellChart1 > selectWell ERROR = ', err.message);
    }
  },


//**************************************************************
//**************************************************************
//******* UpdateChart
//**************************************************************
//**************************************************************
  async UpdateChart () {
    // today can be adjusted via the config file if we decide to uncomment that here for testing
    //  const config = await ConfigService.settingsGetOverview()
    let today = moment().format('YYYY-MM-DD')
    let sel_drange_id = this.sel_drange.id
    let startdate, enddate, daterange
    startdate = null
    //******* Which Date Range Mode? ********/
    if (!this.sw_dmode) {
      // Preset Mode - set startdate/enddate here
      switch (Number.parseInt(sel_drange_id)) {
        case 0: { // This Week
          daterange = this.GetWeekRange2(today)
          startdate = daterange[0] + " 00:00:00"
          enddate = daterange[1] + " 23:59:59"
          break;
        }
        case 1: { // Last Week
          daterange = this.GetWeekRange2(today)
          startdate = daterange[2] + " 00:00:00"
          enddate = daterange[3] + " 23:59:59"
          break;
        }
        case 2: { // Today
          startdate = moment(today).format('YYYY-MM-DD') + " 00:00:00"
          enddate = moment(today).format('YYYY-MM-DD') + " 23:59:59"
          break;
        }
        case 3: { // Yesterday
          startdate = moment(today).subtract(1,'days').format('YYYY-MM-DD') + " 00:00:00"
          enddate = moment(today).subtract(1,'days').format('YYYY-MM-DD') + " 23:59:59"
          break;
        }
      }
    } else {
      // Custom Date Range Mode - user selects start and end dates
      startdate = this.datastartdate + " 00:00:00"
      enddate = this.dataenddate + " 23:59:59"
    }

//*******
//******* Now we know the date range
//******* Get data and prep for chart input via props ********/
//*******
    //*** Test Data Only
    // startdate = "2022-03-02 00:00:00"
    // enddate =   "2022-03-03 23:59:59"
    let sel_groupby_id = this.sel_groupby.id
    // let sel_groupby_id = this.sel_groupby.id
    this.clearChartData()
    let commonDataX=[]
    let barChart1Datay = [], lineChart1Datay=[], lineChart2Datay=[]
    let sel_well = this.sel_well
    let sel_dsbar1 = this.sel_dsbar1, sel_dsline1 = this.sel_dsline1, sel_dsline2 = this.sel_dsline2
    let barChart1Hidden, lineChart1Hidden, lineChart2Hidden = false
    let yAxis1Left = this.yAxis1Left, yAxis1Right = this.yAxis1Right
    let yAxis1LeftLabel=this.yAxis1LeftLabel

//*******
//******* Data is selected to chart
//*******
// debugger
try {
let data
switch (sel_groupby_id) {
  //*****************************
  // Hourly
  //*****************************
  case '0': { 
    data = await ApplianceService.getData(startdate, enddate, sel_well.applUid, 'volumes', 'hour')
    break; } // end -  Hourly

  //*****************************
  // Daily
  //*****************************
  case '1': { 
    data = await ApplianceService.getData(startdate, enddate, sel_well.applUid, 'volumes', 'day')
    break; }  // end - Daily


    } // end switch
    // console.log('[returned] data = '+ data)
    // console.log('debug break')

//**************************************************************
// We have Data to plot!
//  Transform data from query results into prop arrays 
//  to drive VerticalComboChart4
//**************************************************************
// Each iteration of this for loop is a period's worth of data
for (let i = 0 ; i < data.length; i++) {
  commonDataX.push(i.toString()) // period number
  barChart1Datay.push(data[i].period_outflow)
  lineChart1Datay.push(data[i].period_endvolume)
  lineChart2Datay.push(data[i].period_inflow)
}

// Populate the data summary table variables
  // this.datastartdate = startdate
  // this.dataenddate = enddate
  this.initSummaryTable(data[data.length-1]) 

// Set the report title
  this.wellchart1title = this.reportname +" (" + startdate + " - " + enddate + ")"
  this.keychart++
  this.dontshow_chart = false
  //******************************************
  //******* Copy results to Instance variables
  //******************************************
  this.Chart1Labels=commonDataX
  this.barChart1Datax=commonDataX
  this.lineChart1Datax=commonDataX
  this.lineChart2Datax=commonDataX
  this.barChart1Datay = barChart1Datay
  this.lineChart1Datay = lineChart1Datay
  this.lineChart2Datay = lineChart2Datay
  this.barChart1Hidden = barChart1Hidden
  this.lineChart1Hidden = lineChart1Hidden
  this.lineChart2Hidden = lineChart2Hidden
  this.yAxis1Left = yAxis1Left
  this.yAxis1Right = yAxis1Right
  this.yAxis1LeftLabel = yAxis1LeftLabel
  // this.yAxis1RightLabel = yAxis1RightLabel

} // End - we have data to plot
      
 catch (err) {
    console.log('WellChart1 - UpdateChart: err= ', err.message)
  }
  }, // End UpdateChart

}, // End METHODS

//************************
// LIFECYCLE HOOKS
//************************
created () {
  this.initAll()
  // debugger
},

} // Export default end bracket

</script>

<!-- ************************** -->
<!-- *****  LOCAL STYLES  ***** -->
<!-- ************************** -->

<style lang="scss" scoped>
#table > .v-data-header .v-text {
  color: rgb(248, 2, 2);
    font-size: 14px ;
  }

  .v-toolbar__title {
    font-size: 22px;
  }

</style>